// Loading screen style
.loading-screen {
  z-index: z('loading-screen');
}
.wave {
  width: 4px;
  height: 20px;
  background: white;
  box-shadow: 0 0 5px rgba(white, 0.5);
  opacity: 0.2;
  margin: 5px;
  animation: wave 1s linear infinite;
  border-radius: 20px;
}
.wave:nth-child(2) {
  animation-delay: 0.1s;
}
.wave:nth-child(3) {
  animation-delay: 0.2s;
}
.wave:nth-child(4) {
  animation-delay: 0.3s;
}
.wave:nth-child(5) {
  animation-delay: 0.4s;
}
.wave:nth-child(6) {
  animation-delay: 0.5s;
}
.wave:nth-child(7) {
  animation-delay: 0.6s;
}
.wave:nth-child(8) {
  animation-delay: 0.7s;
}
.wave:nth-child(9) {
  animation-delay: 0.8s;
}
.wave:nth-child(10) {
  animation-delay: 0.9s;
}

@keyframes wave {
  0% {
    transform: scale(0);
    opacity: 0.2;
  }
  50% {
    transform: scale(1);
    opacity: 0.8;
  }
  100% {
    transform: scale(0);
    opacity: 0.2;
  }
}

// Loader style
.spinner {
  width: 40px;
  height: 40px;
  background-color: rgba(194, 228, 209, 0.2);
  overflow: hidden;
  position: relative;
  animation: rotate 1s linear infinite both;
}
.spinner::before {
  content: '';
  display: block;
  width: 20px;
  height: 20px;
  background-color: rgba(194, 228, 209, 0.7);
}
.spinner::after {
  content: '';
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #1c222b;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
