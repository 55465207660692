.font-sans {
  font-family: sans-serif;
}

.font-orange {
  color: $orange;
}

.font-seafoam {
  color: $seafoam;
}

.font-white {
  color: $white;
}

.font-army {
  color: $army;
}

.font-bold {
  font-weight: bold;
}

.font-primary {
  font-size: 1.5rem;
}

.font-secondary {
  font-size: 1rem;
}

.font-small {
  font-size: 0.8rem;
}

.font-underline {
  text-decoration: underline;
}

.font-en {
  font-family: 'Raleway', sans-serif;
}
