@mixin modal-overlay() {
  height: 100vh;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  background-color: rgba(121, 145, 140, 0.5);
  outline: none;
}

@mixin modal-content() {
  position: relative !important;
  inset: 0 !important;
  opacity: 0;
  transition: all 500ms ease-in-out;
  outline: none !important;
}

.LoginModal__Overlay,
.ConfirmDeleteModal__Overlay {
  @include modal-overlay();
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 1000ms ease-in-out;
  z-index: z('login-modal');
  .LoginModal__Content,
  .ConfirmDeleteModal__Content {
    @include modal-content();
    background: $middlegrey !important;
    width: 280px;
    transform: translateY(100px);
    transition: all 500ms ease-in-out;
    border-radius: 1rem;
  }
  .LoginModal__Content {
    height: 400px;
    overflow: hidden !important;
  }
}

.LoginModal__Overlay--after-open,
.ConfirmDeleteModal__Overlay--after-open {
  opacity: 1;
  .LoginModal__Content,
  .ConfirmDeleteModal__Content {
    opacity: 1;
    transform: translateY(0px);
  }
}

.LoginModal__Overlay--before-close,
.ConfirmDeleteModal__Overlay--before-close {
  opacity: 0;
  .LoginModal__Content,
  .ConfirmDeleteModal__Content {
    opacity: 0;
    transform: translateY(100px);
  }
}

.MenuModal__Overlay {
  @include modal-overlay();
  display: flex;
  justify-content: flex-end;
  transition: opacity 1000ms ease-in-out;
  z-index: z('menu-modal');
  .MenuModal__Content {
    @include modal-content();
    width: 24rem;
    height: 100vh;
    padding: 0;
    transform: translateX(100px);
    transition: all 500ms ease-in-out;
  }
}

.MenuModal__Overlay--after-open {
  opacity: 1;
  .MenuModal__Content {
    opacity: 1;
    transform: translateX(0px);
  }
}

.MenuModal__Overlay--before-close {
  opacity: 0;
  .MenuModal__Content {
    opacity: 0;
    transform: translateX(100px);
  }
}
