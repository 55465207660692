@function z($name) {
  $index: index($z-indexes, $name);
  @if $index {
    @return $index - 2;
  } @else {
    @warn 'There is no item named "#{$name}", please choose one of: #{$z-indexes}';
    @return null;
  }
}

// default for skipping value 0
$z-indexes: (
  'hero-img',
  'page',
  'player',
  'error-page',
  'navbar',
  'editor-header',
  'menu',
  'menu-modal',
  'menu-button',
  'login-modal',
  'loading-screen'
);
