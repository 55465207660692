.hero-img {
  width: 1500px;
  z-index: z('hero-img');
}
.home-login {
  top: calc(50% - 30px);
  left: calc(50% + 40px);
  width: 130px;
}
.home-editor {
  top: calc(50% + 140px);
  left: calc(50% + 0px);
  width: 250px;
}
.home-list {
  top: calc(50% + 260px);
  left: calc(50% - 200px);
  width: 130px;
}
.home-beat {
  top: calc(50% + 55px);
  left: calc(50% - 405px);
  width: 150px;
}
.loop-icon {
  filter: brightness(10);
  &.active {
    filter: none;
  }
}
