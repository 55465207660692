input {
  width: 100%;
  background: transparent;
  outline: none;
  color: $lightgrey;

  &:focus {
    outline: none !important;
  }
}
