.button-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.submit-button {
  padding: 0.8rem 2rem;
  border: 1px solid $orange;
  color: $orange;
  margin-bottom: 2rem;
  width: 12rem;
  text-align: center;
  cursor: pointer;
  &:hover,
  &.signedUp {
    color: $mint;
    background: $orange;
  }
  &.dark {
    border: 1px solid $seafoam;
    color: $seafoam;
    &:hover {
      color: black;
      background: $seafoam;
    }
  }
  &.small {
    width: 6rem;
  }
  @include mq(mobile) {
    width: 2rem;
    height: 1.4rem;
    line-height: 1.4rem;
    letter-spacing: 0.08rem;
    font-size: 0.85rem;
  }
}
