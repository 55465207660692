$orange: rgb(252, 77, 31);
$electric-orange: #ff3503;
$seafoam: rgb(108, 255, 50);
$army: rgb(72, 110, 14);
$mint: rgb(194, 228, 209);
$darkgrey: rgb(26, 26, 26);
$middlegrey: #282c34;
$lightgrey: #c6c6c6;
$whitesmoke: #f5f5f5;
$white: #fff;
