.lazyloading {
  opacity: 0;
}

.lazyloaded {
  opacity: 1;
  transition: all 700ms;
}

.fade-appear,
.fade-enter {
  opacity: 0;
  z-index: 1;
}
.fade-appear-active,
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 400ms linear 200ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 400ms linear;
}
