@import-normalize;
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@400;500;700&display=swap');
@import 'modules';
@import 'partials';
@import 'vendors';
@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  overflow-x: hidden;
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Noto Sans TC', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $darkgrey;
  color: $mint;
}

#root {
  height: 100%;
}

.flex-0-auto {
  flex: 0 0 auto;
}

.page {
  position: absolute;
  top: 10rem;
  left: 0;
  right: 0;
  min-height: calc(100vh - 10rem);
  z-index: z('page');
}

@import 'page';
@import 'component';

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.5rem;
}

ul {
  list-style-type: square;
}

#play-icon,
#mute-icon {
  path {
    stroke: $mint;
  }
}
